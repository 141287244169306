import { graphql, useStaticQuery } from 'gatsby'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Terms Section */
export interface KenticoTermsSection extends KontentItem {
  header: KontentTextElement
  title: KontentTextElement
  text: KontentRichTextElement
}

export class TermsSection {
  header: string
  title: string
  text: string

  constructor(data: KenticoTermsSection) {
    this.header = _get(data, 'header.value', '')
    this.title = _get(data, 'title.value', '')
    this.text = _get(data, 'text.value', '')
  }
}

export const termsSectionFragment = graphql`
query TermsSectionQuery {
  allKontentItemPage {
    nodes {
      elements {
        content {
          value {
            ... on kontent_item_terms_section {
              elements {
                ...kontent_item_terms_section_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const termsSectionElementsFragment = graphql`
  fragment kontent_item_terms_section_elementsFragment on kontent_item_terms_section_elements {
    header {
      value
    }
    title {
      value
    }
    text {
      value
    }
  }
`

export const useTermsSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(termsSectionFragment)
  const processSectionData: KenticoTermsSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new TermsSection(processSectionData)
}