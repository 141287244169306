import * as React from 'react'
import { RichTextElement } from '@kentico/gatsby-kontent-components'
import { TermsSection as TermsSectionClass } from './model'
import './style.scss'

const TermsSection: React.FC<TermsSectionClass> = ({
  header,
  title,
  text,
}) => {
  return (
    <section className="TermsSection GlobSectPadSpacing mx-mobile-xs lg:mx-m">
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      <div className="grid grid-cols-12 gap-x-m">
        <div className="col-span-12 lg:col-span-6">
          {header && <h1 className="FontL mb-mobile-s lg:mb-s">
            {header}
          </h1>}
          {title && <h1 className="FontXL">
            {title}
          </h1>}
        </div>
        <div className="TermsSection__content FontS col-span-12 pt-mobile-l lg:pt-xl">
          <RichTextElement value={text} />
        </div>
      </div>
    </section>
  )
}

export default TermsSection
