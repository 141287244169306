import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

//components
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import TermsSection from '../components/TermsSection'
import ClientsSection from '../components/ClientsSection'
import ImageVideoSection from '../components/ImageVideoSection'
import FloatingButton from '../components/FloatingButton'
import Footer from '../components/Footer'

//models, hooks
import { IsMobileContext, LanguageContext } from '../common/Context'
import { useSeoData } from '../components/SeoComponent/model'
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'
import { useTermsSectionData, TermsSection as TermsSectionClass } from '../components/TermsSection/model'
import { useClientsSectionData, ClientsSection as ClientsSectionClass } from '../components/ClientsSection/model'
import { useImageVideoSectionData, ImageVideoSection as ImageVideoSectionClass } from '../components/ImageVideoSection/model'

//other
import withSSR from '../hoc/withSSR'
import './blog.scss'

const TermsPage: React.FC<PageProps> = ({ location: { pathname } }) => {
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData), 
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for this elements and collects the right data to components  */
  const pageCodename = 'terms_page'
  const seoData = useSeoData(language, pageCodename, pathname)
  const pageData = {
    linksGridData: useLinksGridData(language, pageCodename),
    navbarData: useNavbarData(language, pageCodename, pathname),
    termsData: useTermsSectionData(language, pageCodename),
    clientsSectionData: useClientsSectionData(language, pageCodename),
    imageVideoSectionData: useImageVideoSectionData(language, pageCodename),
  }

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <TermsPageTemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface TermsPageProps {
  isMobile: boolean
  pageData: {
    linksGridData: LinksGrid
    navbarData: NavbarClass
    termsData: TermsSectionClass
    clientsSectionData: ClientsSectionClass
    imageVideoSectionData: ImageVideoSectionClass
  }
}

const TermsPageTemplate: React.FC<TermsPageProps> = ({
  isMobile,
  pageData: {
    linksGridData,
    navbarData,
    termsData,
    clientsSectionData,
    imageVideoSectionData,
  }
}) => {

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} />
      </header>
      <main className="TermsPage">
        <div className="pt-mobile-m lg:pt-m"></div>
        <TermsSection {...termsData} />
        <ClientsSection {...clientsSectionData} />
        <ImageVideoSection {...imageVideoSectionData} />
      </main>
      <footer>
        <Footer {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const TermsPageTemplateWithSSR = withSSR<TermsPageProps>(TermsPageTemplate)

export default TermsPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
